import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { useState, useEffect } from "react";

import "./index.css";

import NoPage from "./pages/NoPage";
import Admin from "./pages/admin/Admin";
import Login from "./pages/login/Login";
import Cookies from "js-cookie";
import { DesignSystemProvider, useBrowserLanguage } from "@colorkrew/design-system";
import { trans } from "./util/trans";
import Home, { DisplayType } from "./pages/home/Home";
import { GlobalNavigate } from "./components/GlobalNavigate/GlobalNavigate";
import { UserContextProvider } from "./contexts/UserContext";
import Logout from "./components/Logout/Logout";
import AppBar from "./components/AppBar/AppBar";

initializeIcons();

export default function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);

    const language = useBrowserLanguage();
    document.documentElement.lang = language;
    trans.setLang(language);

    useEffect(() => {
        setIsLoggedIn(Cookies.get("is_logged_in") === "true");
        setIsAdmin(Cookies.get("user_role") === "Admin");
    }, []);

    return (
        <DesignSystemProvider
            options={{
                themeName: "openai",
                platform: "desktop",
                language: language,
                customizations: undefined
            }}
        >
            <BrowserRouter>
                <GlobalNavigate />
                <Routes>
                    <Route element={<UserContextProvider />}>
                        <Route path="/" element={isLoggedIn ? <Navigate to="/chat" /> : <Navigate to="/login" />} />
                        <Route path="/chat" element={isLoggedIn ? <Home initialDisplay={DisplayType.CHAT} /> : <Navigate to="/login" />} />
                        <Route path="/chat/:chatId" element={isLoggedIn ? <Home initialDisplay={DisplayType.CHAT} /> : <Navigate to="/login" />} />
                        <Route path="/summary" element={isLoggedIn ? <Home initialDisplay={DisplayType.SUMMARY} /> : <Navigate to="/login" />} />
                        <Route path="/summary/:summaryId" element={isLoggedIn ? <Home initialDisplay={DisplayType.SUMMARY} /> : <Navigate to="/login" />} />
                        <Route path="/admin" element={isLoggedIn ? <Admin /> : <Navigate to="/login" />} />
                        <Route path="/*" element={<NoPage />} />
                    </Route>
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout />} />
                </Routes>
            </BrowserRouter>
        </DesignSystemProvider>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
